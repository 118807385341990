import * as React from "react";

import Layout from "../components/layout";
// import SEO from "../components/common/seo";

const IndexPage = () => {
  const services = [
    {
      title: "Ετήσιο check up Προστάτη",
      text: "Για Άνδρες άνω των 50 ετών και για άνδρες άνω των 45 με θετικό κληρονομικό ιστορικο.",
    },
    {
      title: "Διερεύνηση αιματουρίας",
      text: "Υπερήχος κύστεως νεφρών προστάτη, Ουρηθροκηστεωσκόπηση.",
    },
    {
      title: "Ουροδυναμικός έλεγχος",
      text: "Υπέρηχος κύστης νεφρών προστάτη, Ουρηθροκυστεοσκόπηση.",
    },
    {
      title: "Αφαίρεση κονδυλωμάτων - διαθερμία ή Laser",
      text: "Καυτηριασμός, αφαίρεση κονδυλωμάτων με τοπική αναισθησία. Αφαίρεση ενδορουρητρηκών κονδυλωμάτων με Laser.",
    },
    {
      title: "Εκτομή Βραχέως Χαλινού",
      text: "Πλαστική αποκατάσταση βραχέως χαλινού με τοπική αναισθησία.",
    },
    {
      title: "Ανωδυνη κυστεοσκόπηση",
      text: "Ουρηθροκυστεοσκόπηση με εύκαμπτο κυστεοσκόπιο και ταυτοχρονη βιντεοκαταγραφή.",
    },
    {
      title: "Διαστολές ουρήθρας",
      text: "Διεύρυνση στενωμένου ουρηθρικού αυλού με ειδικούς μαλακούς καθετήρες.",
    },
    {
      title: "Στυτική Δυσλειτουργία",
      text: "Διερευνηση αιτιολογίας, υπέρηχος γεννητικών οργάνων, doppler αγγείων πέους.",
    },
    {
      title: "Υπογονιμότητα",
      text: "Κλινική εξέταση, υπέρηχος γεννητικών οργάνων, περεταίρο έλεγχος σε συνεργασία με μοριακούς βιολόγους, βιοπαθολόγους.",
    },
    {
      title: "Χρόνια προστατίτιδα",
      text: "Διερεύνηση και αντιμετώπιση βακτηριακής και μη-βακτηριακής προστατίτιδας.",
    },
    {
      title: "Λοιμώξεις ουροποιητικού",
      text: "Ιστορικό, Κλινική εξέταση ultrasound ουροποιητικού και περεταίρο έλεγχος για μικροβιολογικές εξετάσεις..",
    },
  ];

  return (
    <Layout>
      <div className="container services">
        <h1 className="pageTitle">Υπηρεσίες</h1>
        <div className="pageContent col3-grid">
          {services.map((item, index) => {
            return (
              <div className="col3-grid-item" key={index}>
                <h3 className="col3-grid-title">{item.title}</h3>
                <div className="services-text">{item.text}</div>
              </div>
            );
          })}
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
